<template>
  <div class="w100w">
    <div class="moneyread">
      <h3><!--img src="@/assets/img/mypage_point_waring.png"-->{{$t('front.cash.moneyCheckList')}}</h3>
      <ul>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.point.moneyPoint')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.point.limitPoint')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListD')}}</li>
      </ul>
    </div>

    <div class="moneyinfoappli">
      <div class="moneyinfo">
        <h3 class="applih">{{$t('front.point.changePoint')}}</h3>
        <p class="applip b0 pb0 mb0">{{$t('front.point.changePointA')}} <span class="blc">1,000</span>P {{$t('front.point.changePointB')}}</p>
      </div>

      <div class="appliinputwrap">
        <ul class="appliinput">
          <li>{{$t('front.point.leftPoint')}}</li>
          <li><span class="blc w260">{{thousand(currentPoint.toString())}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.expectPoint')}}</li>
          <li><span class="w260">{{changePoint}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.expectToPoint')}}</li>
          <li><span class="w260">{{thousand(remainPoint.toString())}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.pointInput')}}</li>
          <li>
             <input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="changePoint">
             <div class="moneybtnwrap">
               <a @click="setPoint(10000)">1{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(30000)">3{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(50000)">5{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(100000)">10{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(300000)">30{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(500000)">50{{$t('front.cash.manwon')}}</a>
               <a @click="setPoint(0)">{{$t('front.cash.correct')}}</a>
               <a @click="setPoint('all')">{{$t('front.cash.all')}}</a>
             </div>
          </li>
        </ul>
        <div class="applibtns">
          <a @click="onSubmit">{{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="betlistmodal" v-if="isPopupOpen">
    <div class="betlist-content w600">
      <h4 class="betlist-title">{{$t('front.point.pointChangeApply')}}</h4>
      <div  class="coupongift">
        <div class="moneyread">
          <h3><img src="@/assets/img/mypage_point_waring.png">{{$t('front.cash.moneyCheckList')}}</h3>
          <ul>
            <li><em>✓</em> {{$t('front.point.moneyPoint')}}</li>
            <li><em>✓</em> {{$t('front.point.limitPoint')}}</li>
            <li><em>✓</em> {{$t('front.cash.moneyCheckListD')}}</li>
          </ul>
        </div>
        <p class="giftname mt20">{{$t('front.point.ablePoint')}}:<span class="bl b">278</span>P</p>
        <p class="giftpick">{{$t('front.point.transPoint')}}<input type="text" placeholder="0" /></p>
        <div class="pointbtn"><a class="blsbtn">{{$t('front.point.pointTrans')}}</a><a class="blksbtn">{{$t('front.stributor.detailList')}}</a></div>
      </div>

      <div class="betclose" @click="isPopupOpen = false"><img src="@/assets/img/wcloseicon.png" /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setChangePoint } from '@/api/point'
import { thousand } from '@/libs/utils'

export default {
  name: 'pointUse',
  watch: {
    changePoint () {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  created () {
    this.currentPoint = this.userData.pointAmt || 0
  },
  data () {
    return {
      isPopupOpen: false,
      currentPoint: '',
      changePoint: '',
      remainPoint: 0,
      limitMinPoint: 10000
    }
  },
  methods: {
    setPoint (value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }

      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit () {
      if (this.changePoint) {
        if (this.limitMinPoint > this.changePoint) {
          this.onCheck('front.point.limitPoint')
          return false
        }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '0') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    }
  }
}
</script>
<style scoped>

</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
